<template>
  <div class="mt-1 mx-3">
    <div>
      <b-tabs content-class="pt-1" lazy v-model="tabIndex">
        <b-tab
          v-for="(tab, index) in tabs"
          :key="index"
          @click="onTabClick(tab, index)"
          :title="tab.text"
          :active="active(index)"
          title-item-class="lisaweb"
        >
          <keep-alive>
            <component :is="selected"></component>
          </keep-alive>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import Consumptions from "@/components/profile/emessage/consumptions/index.vue";
import Credentials from "@/components/profile/emessage/credentials/index.vue";
import Panel from "@/components/profile/emessage/panel/index.vue";

import ShortcutsAndBreadcrumbsMixin from "@/mixins/ShortcutsAndBreadcrumbsMixin";

export default {
  mixins: [ShortcutsAndBreadcrumbsMixin],
  data() {
    return {
      path: "module.PROF",
      tabs: [
        { name: "Consumptions", text: "Consumi" },
        { name: "Credentials", text: "Utenza" },
        { name: "Panel", text: "Panello eMessage" },
      ],
      tabsGroup: "Management",
      // breadcrumbs settings: bcs & maxLevel
      bcs: [
        {
          text: "Profilo",
          path: "",
          level: 0,
          disabled: true,
        },
        {
          text: "Profilo",
          path: "",
          level: 1,
        },
        {
          text: "eMessage",
          path: "",
          level: 2,
        },
        {
          text: "Consumptions",
          path: "module.PROF",
          tab: `#${this.selected}`,
          level: 3,
        },
      ],
      maxLevel: 3, // use to identify the tabs level
      selected: "Consumptions",
      tabIndex: 0,
      // isLoading: false,
    };
  },
  components: {
    Consumptions,
    Credentials,
    Panel,
  },
};
</script>
